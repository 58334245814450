import * as React from "react";

import EmptyState from "src/components/EmptyState";
import { ReactComponent as ErrorIllustration } from "src/illustrations/error.svg";

interface Props {
  children: React.ReactNode;
}

interface State {
  error: typeof Error | null;
}

// const MISSING_ERROR = "Error was swallowed during propagation.";

// export const withErrorBoundary = <BaseProps extends {}>(
//   BaseComponent: React.ComponentType<BaseProps>
// ) => {
//   type HocProps = {
//     // here you can extend hoc with new props
//   };
//   type HocState = {
//     readonly error: Error | null | undefined;
//   };

//   return class Hoc extends React.Component<HocProps, HocState> {
//     // Enhance component name for debugging and React-Dev-Tools
//     static displayName = `withErrorBoundary(${BaseComponent.name})`;

//     // reference to original wrapped component
//     static readonly WrappedComponent = BaseComponent;

//     readonly state: HocState = {
//       error: undefined,
//     };

//     static getDerivedStateFromError(error: Error) {
//       console.log("Hello");
//       return { error: error || new Error(MISSING_ERROR) };
//     }

//     componentDidCatch(error: Error | null, info: object) {
//       console.log("goodby");
//       // this.setState({ error: error || new Error(MISSING_ERROR) });
//       this.logErrorToCloud(error, info);
//     }

//     logErrorToCloud = (error: Error | null, info: object) => {
//       // TODO: send error report to service provider
//     };

//     render() {
//       const { children, ...restProps } = this.props;
//       const { error } = this.state;

//       if (error) {
//         return <BaseComponent error={error} {...(restProps as BaseProps)} />;
//       }

//       return children;
//     }
//   };
// };

// const ErrorBoundaryRenderer = ({ error }: { error: Error }) => {
//   return (
//     <EmptyState
//       image={<ErrorIllustration />}
//       title="Something went wrong"
//       description={error.stack}
//     />
//   );
// };

// const ErrorBoundary = withErrorBoundary(ErrorBoundaryRenderer);

class ErrorBoundary extends React.Component<Props, State> {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    console.log("error");
    return { error };
  }

  componentDidCatch(_error: any, _errorInfo: any) {
    console.log("error did catch");
    // TODO: Log to analytics? or firestore?
  }

  render() {
    // Properties
    const { children } = this.props;

    const { error } = this.state;

    if (error !== null) {
      return (
        <EmptyState image={<ErrorIllustration />} title="Something went wrong" description={"Refresh the page?"} />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
