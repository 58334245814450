"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.Project = void 0;
var dao_1 = require("./dao");
var ProjectDAO = /** @class */ (function (_super) {
    __extends(ProjectDAO, _super);
    function ProjectDAO() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = "projects";
        _this.converter = {
            toFirestore: function (_a) {
                var name = _a.name, users = _a.users;
                return {
                    name: name,
                    users: users
                };
            },
            fromFirestore: function (snapshot, options) {
                var _a, _b;
                var data = snapshot.data(options);
                var name = (_a = data.name) !== null && _a !== void 0 ? _a : "";
                var users = (_b = data.users) !== null && _b !== void 0 ? _b : [];
                return {
                    id: data.id,
                    name: name,
                    users: users
                };
            }
        };
        return _this;
    }
    return ProjectDAO;
}(dao_1.DAO));
exports.Project = new ProjectDAO();
exports["default"] = exports.Project;
// /**
//  * Actions related to the administration of projects.
//  */
// export default class Project {
//   static listProjects = async (): Promise<ProjectDocument[]> => {
//     const projectCollectionReference = firestore.collection(PROJECTS_COLLECTION_NAME);
//     const projectDocuments = await projectCollectionReference.get();
//     return projectDocuments.docs.map(Project.convert).sort(sortBy("name"));
//   };
//   static listMyProjects = async (): Promise<ProjectDocument[]> => {
//     const user = auth.currentUser;
//     if (!user) {
//       throw new Error("User not logged in");
//     }
//     const userRef = Users.getDocumentReference(user.uid);
//     const query: firebase.firestore.Query = firestore
//       .collection(PROJECTS_COLLECTION_NAME)
//       .where(PROJECT_ATTRIBUTE.USERS, "array-contains", userRef);
//     const projectDocuments = await query.get({ source: "server" });
//     return projectDocuments.docs.map(Project.convert).sort(sortBy("name"));
//   };
//   static subscriberMyProjects = (
//     onSnapshot: (snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => void
//   ): (() => void) => {
//     const user = auth.currentUser;
//     if (!user) {
//       throw new Error("User not logged in");
//     }
//     const userRef = Users.getDocumentReference(user.uid);
//     return firestore
//       .collection(PROJECTS_COLLECTION_NAME)
//       .where(PROJECT_ATTRIBUTE.USERS, "array-contains", userRef)
//       .onSnapshot(onSnapshot);
//   };
//   static createProject = async (project: CreateProjectDocument): Promise<void> => {
//     const projectCollectionReference = firestore.collection(PROJECTS_COLLECTION_NAME);
//     await projectCollectionReference.add(project);
//   };
//   static updateProject = async (id: string, project: CreateProjectDocument): Promise<void> => {
//     const projectReference = firestore.collection(PROJECTS_COLLECTION_NAME).doc(id);
//     await projectReference.update(project);
//   };
//   static deleteProject = async (id: string): Promise<void> => {
//     const projectReference = firestore.collection(PROJECTS_COLLECTION_NAME).doc(id);
//     await projectReference.delete();
//   };
//   static getDocumentReference = (uid: string): firebase.firestore.DocumentReference => {
//     return firestore.collection(PROJECTS_COLLECTION_NAME).doc(uid);
//   };
//   static convert = (data: firebase.firestore.DocumentSnapshot): ProjectDocument => {
//     const name = data.get(PROJECT_ATTRIBUTE.NAME) ?? "";
//     const users = data.get(PROJECT_ATTRIBUTE.USERS) ?? [];
//     return {
//       id: data.id,
//       name,
//       users,
//     };
//   };
// }
