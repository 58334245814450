import React from "react";

import { useTranslation } from "react-i18next";
import CommonDialog from "../CommonDialog";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

interface Props {
  onClose: () => void;
}

const AboutDialog = ({ onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <CommonDialog title={t("about.title")} onClose={onClose} dialogProps={{ fullWidth: true, maxWidth: "xs" }}>
      <DialogContent>
        <List disablePadding>
          <ListItem>
            <ListItemText primary={t("about.appName")} secondary={process.env.REACT_APP_TITLE} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t("about.version")} secondary={process.env.REACT_APP_VERSION} />
          </ListItem>
        </List>
      </DialogContent>
    </CommonDialog>
  );
};

export default AboutDialog;
