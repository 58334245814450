"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.Credentials = void 0;
var dao_1 = require("./dao");
var CredentialsDAO = /** @class */ (function (_super) {
    __extends(CredentialsDAO, _super);
    function CredentialsDAO() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = "credentials";
        _this.converter = {
            toFirestore: function (_a) {
                var devices = _a.devices;
                return {
                    devices: devices
                };
            },
            fromFirestore: function (snapshot, options) {
                var _a;
                var data = snapshot.data(options);
                var devices = (_a = data.devices) !== null && _a !== void 0 ? _a : [];
                return {
                    id: data.id,
                    devices: devices
                };
            }
        };
        return _this;
    }
    return CredentialsDAO;
}(dao_1.DAO));
exports.Credentials = new CredentialsDAO();
exports["default"] = exports.Credentials;
