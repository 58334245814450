import * as React from "react";

import { DialogProps } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface Props {
  children: React.ReactNode;
  dialogProps?: Omit<DialogProps, "open">;
  onClose: () => void;
  title: string;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

export const CommonDialog = ({ children, dialogProps, onClose, title }: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const extraDialogProps = dialogProps ?? {};

  return (
    <Dialog fullScreen={fullScreen} onClose={onClose} open {...extraDialogProps}>
      <DialogTitle disableTypography>
        <Typography variant="h6">{title}</Typography>

        <Tooltip title="Close">
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      {children}
    </Dialog>
  );
};

export default CommonDialog;
