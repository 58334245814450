import React from "react";

// External Imports
// =============================================================================
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
  },
}));

interface Props {
  children: JSX.Element;
}

export const Layout = ({ children }: Props) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  let spacer: JSX.Element | null = <div className={classes.appBarSpacer} />;

  if (["/welcome"].includes(pathname)) {
    spacer = null;
  }

  return (
    <main className={classes.content}>
      {spacer}
      <Container maxWidth="lg" className={classes.container} disableGutters>
        {children}
      </Container>
    </main>
  );
};

export default Layout;
