import React from "react";

import { auth } from "firebase-admin";
import { getRoles } from "./auth";
import {
  AssertionCredentialJSON,
  AttestationCredentialJSON,
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
} from "@simplewebauthn/typescript-types";
import { useUser } from "reactfire";
import api from "src/lib/api";
import firebase from "firebase";

interface AttestationResponse {
  verified: boolean;
}

interface AssertionResponse {
  token: string;
}

export const useRoles = () => {
  const { data: currentUser } = useUser();

  const [idToken, setIdToken] = React.useState<firebase.auth.IdTokenResult>();

  React.useEffect(() => {
    const getToken = async () => {
      const idToken = await currentUser.getIdTokenResult();
      setIdToken(idToken);
    };

    if (currentUser) {
      getToken();
    }
  }, [currentUser?.metadata?.lastSignInTime]);

  return { roles: getRoles(idToken) };
};

export const useUsersAPI = () => {
  const { data: currentUser } = useUser();

  return new UsersAPI(currentUser);
};

export class UsersAPI {
  private user: firebase.User;
  private idToken: string | null = null;

  constructor(user: firebase.User) {
    this.user = user;
  }

  private getIDToken = async (): Promise<string> => {
    if (this.idToken === null) {
      this.idToken = await this.user.getIdToken();
    }
    return this.idToken;
  };

  listUsers = async (): Promise<Array<auth.UserRecord>> => {
    const idToken = await this.getIDToken();

    const response = await api("users", { method: "GET" }, idToken);

    const users = response.users as auth.UserRecord[];

    if (!Array.isArray(users)) {
      throw new Error("users is not an array");
    }

    return users;
  };

  createUser = async (user: auth.CreateRequest): Promise<auth.UserRecord> => {
    const idToken = await this.getIDToken();

    const response = await api("users", { method: "POST", body: JSON.stringify(user) }, idToken);

    return response as auth.UserRecord;
  };

  updateUser = async (uid: string, user: auth.CreateRequest): Promise<auth.UserRecord> => {
    const idToken = await this.getIDToken();

    const response = await api(`users?uid=${uid}`, { method: "PUT", body: JSON.stringify(user) }, idToken);

    return response as auth.UserRecord;
  };

  deleteUser = async (uid: string): Promise<void> => {
    const idToken = await this.getIDToken();
    await api(`users?uid=${uid}`, { method: "DELETE" }, idToken, false);
  };

  addRoleToUser = async (uid: string, role: string) => {
    const idToken = await this.getIDToken();
    if (!uid || !role) {
      throw new Error("UID or role not provided");
    }

    await api(
      "roles",
      {
        method: "PUT",
        body: JSON.stringify({ uid, role }),
      },
      idToken
    );
  };

  removeRoleFromUser = async (uid: string, role: string) => {
    const idToken = await this.getIDToken();
    if (!uid || !role) {
      throw new Error("UID or role not provided");
    }

    await api(
      "roles",
      {
        method: "DELETE",
        body: JSON.stringify({ uid, role }),
      },
      idToken
    );
  };

  getAttestationChallenge = async (uid: string): Promise<PublicKeyCredentialCreationOptionsJSON> => {
    const idToken = await this.getIDToken();
    if (!uid) {
      throw new Error("UID not provided");
    }

    const response = await api(`attestation?uid=${uid}`, { method: "GET" }, idToken);

    return response as PublicKeyCredentialCreationOptionsJSON;
  };

  verifyAttestationResponse = async (
    uid: string,
    credential: AttestationCredentialJSON,
    name?: string
  ): Promise<AttestationResponse> => {
    const idToken = await this.getIDToken();
    if (!uid || !credential) {
      throw new Error("UID or credential not provided");
    }

    const response = await api(
      `attestation`,
      { method: "POST", body: JSON.stringify({ uid, credential, name }) },
      idToken
    );

    return response as AttestationResponse;
  };

  getAssertionChallenge = async (email: string): Promise<PublicKeyCredentialRequestOptionsJSON> => {
    if (!email) {
      throw new Error("Email not provided");
    }

    const response = await api(`assertion?email=${email}`, { method: "GET" });

    return response as PublicKeyCredentialRequestOptionsJSON;
  };

  verifyAssertionResponse = async (email: string, credential: AssertionCredentialJSON): Promise<AssertionResponse> => {
    if (!email || !credential) {
      throw new Error("Email or credential not provided");
    }

    const response = await api(`assertion`, { method: "POST", body: JSON.stringify({ email, credential }) });

    return response as AssertionResponse;
  };
}
