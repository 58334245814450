"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.Time = void 0;
var dao_1 = require("./dao");
var TimeDAO = /** @class */ (function (_super) {
    __extends(TimeDAO, _super);
    function TimeDAO() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = "time_tracking";
        _this.converter = {
            toFirestore: function (_a) {
                var date = _a.date, hours = _a.hours, notes = _a.notes, project = _a.project, user = _a.user;
                return {
                    date: date,
                    hours: hours,
                    notes: notes,
                    project: project,
                    user: user
                };
            },
            fromFirestore: function (snapshot, options) {
                var _a, _b, _c, _d, _e;
                var data = snapshot.data(options);
                var date = (_a = data.date) !== null && _a !== void 0 ? _a : "";
                var hours = (_b = data.hours) !== null && _b !== void 0 ? _b : 0;
                var notes = (_c = data.notes) !== null && _c !== void 0 ? _c : "";
                var project = (_d = data.project) !== null && _d !== void 0 ? _d : "";
                var user = (_e = data.user) !== null && _e !== void 0 ? _e : "";
                return {
                    id: data.id,
                    date: date,
                    hours: hours,
                    notes: notes,
                    project: project,
                    user: user
                };
            }
        };
        return _this;
    }
    return TimeDAO;
}(dao_1.DAO));
exports.Time = new TimeDAO();
exports["default"] = exports.Time;
/**
 * Actions related to the administration of time entries.
 */
// export class TimeEntry {
//   static listAllTimeEntries = async () => {
//     return TimeEntry.listTimeEntries(null, null);
//   };
//   static listTimeEntries = async (
//     user: firebase.firestore.DocumentReference | null,
//     project: firebase.firestore.DocumentReference | null,
//     orderBy?: string
//   ): Promise<Array<TimeDocument>> => {
//     let query: firebase.firestore.Query = firestore.collection(TIME_COLLECTION_NAME);
//     if (user) {
//       query = query.where(TIME_ATTRIBUTE.USER, "==", user);
//     }
//     if (project) {
//       query = query.where(TIME_ATTRIBUTE.PROJECT, "==", project);
//     }
//     if (orderBy) {
//       query = query.orderBy(orderBy, "desc");
//     }
//     const timeDocuments = await query.get();
//     return timeDocuments.docs.map(TimeEntry.convert);
//   };
//   static timeEntriesByDate = async (timeEntries: Array<TimeDocument>) => {
//     const entriesByDate: TimeEntries = {};
//     for (let timeEntry of timeEntries) {
//       const date = timeEntry.date.toDate().toISOString();
//       if (!entriesByDate[date]) {
//         entriesByDate[date] = [];
//       }
//       const projectDocument = await timeEntry.project.get();
//       const projectName = Projects.convert(projectDocument).name;
//       entriesByDate[date].push({ ...timeEntry, projectName: projectName });
//     }
//     return entriesByDate;
//   };
//   static timeEntriesByProject = async (timeEntries: Array<TimeDocument>) => {
//     const entriesByProject: TimeEntries = {};
//     for (let timeEntry of timeEntries) {
//       const projectDocument = await timeEntry.project.get();
//       const projectID = projectDocument.id;
//       const projectName = Projects.convert(projectDocument).name;
//       if (!entriesByProject[projectID]) {
//         entriesByProject[projectID] = [];
//       }
//       entriesByProject[projectID].push({ ...timeEntry, projectName: projectName });
//     }
//     return entriesByProject;
//   };
//   static createTimeEntry = async (timeEntry: CreateTimeDocument): Promise<void> => {
//     const timeCollectionReference = firestore.collection(TIME_COLLECTION_NAME);
//     await timeCollectionReference.add(timeEntry);
//   };
//   static updateTimeEntry = async (id: string, data: CreateTimeDocument): Promise<void> => {
//     const timeEntryReference = firestore.collection(TIME_COLLECTION_NAME).doc(id);
//     await timeEntryReference.update(data);
//   };
//   static deleteTimeEntry = async (id: string): Promise<void> => {
//     const timeEntryReference = firestore.collection(TIME_COLLECTION_NAME).doc(id);
//     await timeEntryReference.delete();
//   };
//   static getTimeReference = (uid: string): firebase.firestore.DocumentReference => {
//     return firestore.collection(TIME_COLLECTION_NAME).doc(uid);
//   };
//   static convert = (data: firebase.firestore.DocumentSnapshot): TimeDocument => {
//     // TODO: look up user and project here instead of just returning the reference?
//     const date = data.get(TIME_ATTRIBUTE.DATE) ?? "";
//     const hours = data.get(TIME_ATTRIBUTE.HOURS) ?? 0;
//     const notes = data.get(TIME_ATTRIBUTE.NOTES) ?? "";
//     const project = data.get(TIME_ATTRIBUTE.PROJECT) ?? "";
//     const user = data.get(TIME_ATTRIBUTE.USER) ?? "";
//     return {
//       id: data.id,
//       date,
//       hours,
//       notes,
//       project,
//       user,
//     };
//   };
// }
