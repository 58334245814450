import React from "react";
import firebase from "firebase";

// Local Imports
// =============================================================================
import Bar from "src/components/Bar";
import LaunchScreen from "src/components/LaunchScreen";
import Layout from "src/components/Layout";

// External Imports
// =============================================================================
import {
  AuthCheck,
  preloadAuth,
  preloadFirestore,
  useFirebaseApp,
  useFirestore,
  useFirestoreDocData,
  useUser,
} from "reactfire";
import { Route, RouteProps, Switch } from "react-router-dom";
import { useRoles } from "src/lib/hooks";
import { User, UserDocument } from "@woody-portal/hydra";

// Routes
// =============================================================================
const AccessDenied = React.lazy(() => import("src/components/AccessDenied"));
const AddTimePage = React.lazy(() => import("src/routes/AddTime"));
const MyTimeTracker = React.lazy(() => import("src/routes/MyTimeTracker"));
const LoginPage = React.lazy(() => import("src/routes/LoginPage"));
const MyProfilePage = React.lazy(() => import("src/routes/MyProfile"));
const NotFoundPage = React.lazy(() => import("src/components/NotFoundPage"));
const ProjectsPage = React.lazy(() => import("src/routes/Projects"));
const UsersPage = React.lazy(() => import("src/routes/Users"));
const WelcomPage = React.lazy(() => import("src/routes/Welcome"));

const preloadSDKs = (firebaseApp: firebase.app.App) => {
  return Promise.all([
    preloadFirestore({
      firebaseApp,
    }),
    preloadAuth({ firebaseApp }),
  ]);
};

const Router = () => {
  const firebaseApp = useFirebaseApp();

  preloadSDKs(firebaseApp);

  return (
    <AuthCheck fallback={<LoginPage />}>
      <OnboardedGate>
        <Bar />
        <Layout>
          <Switch>
            <Route path="/" exact>
              <MyProfilePage />
            </Route>

            <Route path="/user/:userId" exact>
              <MyProfilePage />
            </Route>

            <Route path="/mytime" exact>
              <MyTimeTracker />
            </Route>

            <Route path="/time/add" exact>
              <AddTimePage />
            </Route>

            <PrivateRoute path="/users" exact requiredRole="admin">
              <UsersPage />
            </PrivateRoute>

            <PrivateRoute path="/projects" exact requiredRole="admin">
              <ProjectsPage />
            </PrivateRoute>

            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </Layout>
      </OnboardedGate>
    </AuthCheck>
  );
};

export default Router;

type PrivateRouteProps = {
  requiredRole: string;
} & RouteProps;

/**
 * A wrapper for <Route> that checks if the user has the required role.
 */
const PrivateRoute = ({ children, requiredRole, ...rest }: PrivateRouteProps) => {
  const { roles } = useRoles();

  if (requiredRole && !roles.includes(requiredRole)) {
    return <AccessDenied />;
  }

  return (
    <Route
      {...rest}
      render={() => {
        return children;
      }}
    />
  );
};

const OnboardedGate = ({ children }: { children: React.ReactNode }) => {
  const { data: user } = useUser();

  const ref = useFirestore().collection(User.name).withConverter(User.converter).doc(user.uid);

  const userDocument = useFirestoreDocData<UserDocument>(ref, { idField: "id" });

  if (userDocument.status === "loading" || !userDocument.data) {
    return <LaunchScreen color="yellow" />;
  }

  const onboarded = userDocument.data.isOnboarded;

  if (onboarded === false) {
    return (
      <Layout>
        <WelcomPage />
      </Layout>
    );
  }

  return <>{children}</>;
};
