import React from "react";

// Local Imports
// =============================================================================
import { isAdmin } from "src/lib/auth";
import { useRoles } from "src/lib/hooks";
import ConstructionIcon from "src/components/Icons/Construction";

// Material-UI Imports
// =============================================================================
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import TimerIcon from "@material-ui/icons/Timer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

// External Imports
// =============================================================================
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
}));

interface Props {
  isMobile: boolean;
  open: boolean;
  toggleDrawer: (close?: boolean) => void;
}

export default function ResponsiveDrawer({ isMobile, open, toggleDrawer }: Props) {
  const classes = useStyles();
  const { roles } = useRoles();

  const isAnAdmin = isAdmin(roles);

  const { t } = useTranslation();

  return (
    <Drawer
      variant={isMobile ? "temporary" : "permanent"}
      open={open}
      onClose={() => toggleDrawer(true)}
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Hidden xsDown>
        <div className={classes.toolbarIcon}>
          <IconButton onClick={() => toggleDrawer(true)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <Divider />
      </Hidden>

      <List>
        <ListItem button component={Link} to="/mytime" onClick={() => toggleDrawer()}>
          <ListItemIcon>
            <TimerIcon />
          </ListItemIcon>
          <ListItemText primary={t("nav.timeTracker")} />
        </ListItem>

        {isAnAdmin && (
          <ListItem button component={Link} to="/users" onClick={() => toggleDrawer()}>
            <ListItemIcon>
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText primary={t("nav.users")} />
          </ListItem>
        )}

        {isAnAdmin && (
          <ListItem button component={Link} to="/projects" onClick={() => toggleDrawer()}>
            <ListItemIcon>
              <ConstructionIcon />
            </ListItemIcon>
            <ListItemText primary={t("nav.projects")} />
          </ListItem>
        )}
      </List>
    </Drawer>
  );
}
