"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.User = void 0;
var dao_1 = require("./dao");
var UserDAO = /** @class */ (function (_super) {
    __extends(UserDAO, _super);
    function UserDAO() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = "users";
        _this.converter = {
            toFirestore: function (_a) {
                var currentChallenge = _a.currentChallenge, isOnboarded = _a.isOnboarded, linkSent = _a.linkSent;
                return {
                    currentChallenge: currentChallenge,
                    isOnboarded: isOnboarded,
                    linkSent: linkSent
                };
            },
            fromFirestore: function (snapshot, options) {
                var _a, _b, _c;
                var data = snapshot.data(options);
                var currentChallenge = (_a = data.currentChallenge) !== null && _a !== void 0 ? _a : null;
                var isOnboarded = (_b = data.isOnboarded) !== null && _b !== void 0 ? _b : false;
                var linkSent = (_c = data.linkSent) !== null && _c !== void 0 ? _c : null;
                return {
                    id: data.id,
                    currentChallenge: currentChallenge,
                    isOnboarded: isOnboarded,
                    linkSent: linkSent
                };
            }
        };
        return _this;
    }
    return UserDAO;
}(dao_1.DAO));
exports.User = new UserDAO();
exports["default"] = exports.User;
