import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
});

interface Props {
  color?: string;
}

const LaunchScreen = ({ color = "unset" }: Props) => {
  const classes = useStyles();

  if (process.env.NODE_ENV === "production") {
    color = "unset";
  }

  return (
    <Box width="100%" height="100%" position="fixed" top={0} left={0} bgcolor={color}>
      <div className={classes.center}>
        <CircularProgress />
      </div>
    </Box>
  );
};

export default LaunchScreen;
