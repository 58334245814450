import urljoin from "url-join";
import { ApiError } from "@woody-portal/hydra";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

if (!baseUrl) {
  throw new Error("Base URL not set");
} else {
  console.debug("Using Base URL", baseUrl);
}

/**
 * Utility function for calling the Team Portal backend APIs.
 *
 * @param url The URL path
 * @param requestParms The API request parameters
 * @param parseResponse Whether or not to parse the API response as JSON.
 */
export const api = async (
  url: string,
  requestParms: RequestInit,
  idToken?: string,
  parseResponse = true
): Promise<any> => {
  const { headers, ...rest } = requestParms;

  const authHeader = idToken ? { "X-Firebase-ID-Token": idToken } : null;

  const response = await fetch(urljoin(baseUrl, url), {
    headers: {
      "Content-type": "application/json",
      ...authHeader,
      ...headers,
    },
    ...rest,
  });

  let data = null;

  if (!response.ok) {
    const errorBody: ApiError | undefined = await response.json();

    const errorMessage = errorBody?.error ?? response.statusText;

    throw new Error(errorMessage);
  }

  if (parseResponse) {
    data = await response.json();
  }

  return data;
};

export default api;
