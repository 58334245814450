import React from "react";

// Local Imports
// =============================================================================
import AboutDialog from "src/components/AboutDialog";
import CommonDialog from "src/components/CommonDialog";
import NavDrawer from "src/components/NavDrawer";

// Material-UI Imports
// =============================================================================
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// Other Imports
// =============================================================================
import { Link } from "react-router-dom";
import { useAuth } from "reactfire";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  menuButton: {
    marginRight: theme.spacing(3),
  },
  menuButtonHidden: {
    display: "none",
  },
}));

type DialogType = "aboutDialog" | "signOutDialog";

const Bar = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [working, setWorking] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState<DialogType | null>(null);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  if (auth.currentUser === null) {
    return null;
  }

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onOpenDialog = (dialog: DialogType) => {
    closeMenu();
    setOpenDialog(dialog);
  };

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const signOut = async () => {
    setWorking(true);

    try {
      await auth.signOut();
      enqueueSnackbar(t("signOut.success"));
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: "error",
      });
    } finally {
      setWorking(false);
      closeDialog();
    }
  };

  const toggleDrawer = (close = false) => {
    if (isMobile || close) {
      setOpenDrawer(!openDrawer);
    }
  };

  return (
    <>
      <AppBar position="absolute" className={clsx(classes.appBar, openDrawer && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            className={clsx(classes.menuButton, openDrawer && classes.menuButtonHidden)}
            color="inherit"
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            <MenuIcon />
          </IconButton>

          <Box display="flex" flexGrow={1}>
            <Typography color="inherit" variant="h6">
              {process.env.REACT_APP_TITLE}
            </Typography>
          </Box>

          {auth.currentUser && (
            <>
              <IconButton color="inherit" onClick={openMenu} edge="end">
                <AccountCircle />
              </IconButton>

              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                {/* About */}
                <MenuItem onClick={() => onOpenDialog("aboutDialog")}>{t("menu.about")}</MenuItem>

                {/* Profile */}
                <MenuItem component={Link} to={`/user/${auth.currentUser.uid}`} onClick={closeMenu}>
                  {t("menu.profile")}
                </MenuItem>

                <Divider />

                {/* Sign OUt */}
                <MenuItem onClick={() => onOpenDialog("signOutDialog")}>{t("menu.signOut")}</MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>

      <NavDrawer isMobile={isMobile} open={openDrawer} toggleDrawer={toggleDrawer} />

      {openDialog === "aboutDialog" && <AboutDialog onClose={closeDialog} />}

      {openDialog === "signOutDialog" && (
        <CommonDialog
          onClose={closeDialog}
          title={t("signOut.title")}
          dialogProps={{ maxWidth: "sm", fullWidth: true }}
        >
          <DialogContent>
            <Typography>{t("signOut.message")}</Typography>
          </DialogContent>
          <DialogActions>
            <Button color="primary" disabled={working} variant="contained" onClick={signOut}>
              {t("button.signOut")}
            </Button>
          </DialogActions>
        </CommonDialog>
      )}
    </>
  );
};

export default Bar;
