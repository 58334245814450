import firebase from "firebase";

export const getRoles = (idToken: firebase.auth.IdTokenResult | undefined): string[] => {
  return idToken?.claims?.roles ?? [];
};

export const isAdmin = (roles: string[] | void): boolean => {
  return Array.isArray(roles) && roles.includes("admin");
};

export const getUserFromCredential = (credential: firebase.auth.UserCredential): firebase.User => {
  const { user } = credential;

  if (!user) {
    throw new Error("User data not returned from Firebase");
  }

  return user;
};

export const getAuthProviderData = (user: firebase.User, providerId: string): firebase.UserInfo | null => {
  const { providerData } = user;

  const provider = providerData.find((provider) => provider?.providerId === providerId);

  return provider ?? null;
};
