import React from "react";

// Local Imports
// =============================================================================
import ErrorBoundary from "src/components/ErrorBoundary";
import LaunchScreen from "src/components/LaunchScreen";
import Router from "src/components/Router";

// External Imports
// =============================================================================
import { BrowserRouter } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import { FirebaseAppProvider } from "reactfire";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import CssBaseline from "@material-ui/core/CssBaseline";
import DateFnsUtils from "@date-io/date-fns";

// Import auth directly because most components need it
// Other Firebase libraries can be lazy-loaded as-needed
import "firebase/auth";

// From Firebase console
const firebaseConfig = {
  apiKey: "AIzaSyCyfPWqkU7NjvW6PHPd59sGgNP8j_tDj2I",
  authDomain: "woodrow-inc.firebaseapp.com",
  databaseURL: "https://woodrow-inc.firebaseio.com",
  projectId: "woodrow-inc",
  storageBucket: "woodrow-inc.appspot.com",
  messagingSenderId: "661596176487",
  appId: "1:661596176487:web:5eb64d2e18001975f414c2",
  measurementId: "G-8LKSMD2SDM",
};

// TODO: create custom theme
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#233D4D",
    },
    secondary: {
      main: "#F7941D",
    },
  },
});

const App = () => {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<LaunchScreen color="red" />}>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <CssBaseline />
              <SnackbarProvider maxSnack={3}>
                <FirebaseAppProvider firebaseConfig={firebaseConfig}>
                  <Router />
                </FirebaseAppProvider>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default App;
